define("lh-public-olo/models/customer", ["exports", "@ember-data/model", "ember-cp-validations", "@ember/service", "@ember/object/computed"], function (_exports, _model, _emberCpValidations, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: {
      description: 'First Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        disabled: (0, _computed.alias)('model.ticket.dineIn')
      }), (0, _emberCpValidations.validator)('length', {
        max: 30
      })]
    },
    lastName: {
      description: 'Last Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        disabled: (0, _computed.alias)('model.ticket.dineIn')
      }), (0, _emberCpValidations.validator)('length', {
        max: 30
      })]
    },
    email: {
      description: 'Email Address',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        minTldLength: 2
      })]
    },
    phone: {
      description: 'Phone Number',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('phone-number', {
        allowBlank: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    ticket: (0, _service.inject)(),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    deliveryAddresses: (0, _model.hasMany)('delivery-address', {
      async: false
    })
  });

  _exports.default = _default;
});