define("lh-public-olo/services/ticket", ["exports", "@ember/service", "@ember/object", "rsvp", "lh-public-olo/config/environment", "lh-public-olo/helpers/usd", "ember-cp-validations", "lh-public-olo/utils/set-local-storage", "lh-public-olo/utils/get-local-storage", "lh-public-olo/utils/clear-local-storage", "lh-public-olo/utils/time-util", "lh-public-olo/utils/general-error-response-parser", "@ember/runloop", "ember-ajax/errors"], function (_exports, _service, _object, _rsvp, _environment, _usd, _emberCpValidations, _setLocalStorage, _getLocalStorage2, _clearLocalStorage, _timeUtil, _generalErrorResponseParser, _runloop, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    orderTypeGuid: (0, _emberCpValidations.validator)('presence', true),
    tenderTypeGuid: (0, _emberCpValidations.validator)('presence', true),
    selectedTipCustom: {
      validators: [(0, _emberCpValidations.validator)('number', {
        positive: true,
        allowString: true
      }), // gross regex way to make sure the number isn't in scientific notation
      // because keith hates rich people
      (0, _emberCpValidations.validator)('format', {
        regex: /^\d*(\.\d{0,2})?$/
      })],
      description: 'Custom tip',
      disabled: (0, _object.computed)('model.selectedTip', function () {
        return this.get('model.selectedTip') !== 'custom';
      })
    }
  });

  var _default = _service.default.extend(Validations, {
    featureFlag: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    router: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    locationRef: undefined,
    isDeferred: undefined,
    orderTypeGuid: undefined,
    expectedTimeUtc: undefined,
    orderType: undefined,
    tenders: undefined,
    tenderTypeGuid: undefined,
    discountCode: undefined,
    dineIn: false,
    tableNotes: undefined,
    timeZoneOffset: undefined,
    storeSettingsChangedError: null,
    loyaltyDiscount: undefined,
    distanceLeadTimeInMinutes: undefined,
    preferredQuoteId: undefined,
    isContactlessDelivery: false,
    isStoreClosureActive: false,
    activeStoreClosureMessage: undefined,
    selectedTip: undefined,
    customerSelectedDay: undefined,
    dineInAllowDuringOffHours: true,
    referralSource: undefined,
    referralGuid: undefined,
    signInPromptEnabled: true,
    expectedDisplayDate: (0, _object.computed)('expectedTimeUtc', function () {
      var expectedTime = this.expectedTimeUtc;

      if (!expectedTime) {
        return '';
      }

      var displayDay = this.getDisplayDay(expectedTime);
      var displayTime = this.getDisplayTime(expectedTime);
      return "".concat(displayDay, " at ").concat(displayTime);
    }),
    getDisplayDay: function getDisplayDay(date) {
      var storeTime = (0, _timeUtil.getAdjustedTime)(this.timeZoneOffset, new Date(date));
      return (0, _timeUtil.getDisplayDay)(storeTime, this.timeZoneOffset);
    },
    getDisplayTime: function getDisplayTime(date) {
      var storeTime = (0, _timeUtil.getAdjustedTime)(this.timeZoneOffset, new Date(date));
      return storeTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit'
      });
    },
    getLeadTimeInMinutes: function getLeadTimeInMinutes() {
      var orderType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.orderType;

      // use lead time from validate-distance endpoint, otherwise use lead time from location settings
      if (!orderType) {
        return 0;
      }

      if (this.isServedByDDDOrDSP(orderType)) {
        var distanceLeadTimeInMinutes = this.distanceLeadTimeInMinutes;

        if (distanceLeadTimeInMinutes) {
          return distanceLeadTimeInMinutes;
        }
      }

      return orderType.get('leadTimeInMinutes');
    },
    isServedByDDDOrDSP: function isServedByDDDOrDSP() {
      var orderType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.orderType;
      return this.featureFlag.isEnabled('sto-dsp-integration') ? orderType.get('deliveryServedWithDSP') : orderType.get('isServedByDoorDashDrive');
    },
    isItemImagesFeatureEnabled: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-item-images');
    }),
    isDeferredFeatureEnabled: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-deferred-orders');
    }),
    isUserAccountEnabled: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-account-create');
    }),
    isRememberCustomerAccountInfoFeatureEnabled: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-customer-account-remember-information');
    }),
    isAccountManageFeatureEnabled: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-account-manage');
    }),
    isQuantityOnHandFeatureEnabled: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-quantity-on-hand');
    }),
    isOrderConfirmationMessageFeatureEnabled: (0, _object.computed)(function () {
      return this.featureFlag.isEnabled('sto-order-confirmation-message');
    }),
    total: (0, _object.computed)('subtotal', 'calculations', function () {
      return this.get('calculations.totals.grandTotal') === undefined ? this.subtotal : this.get('calculations.totals.grandTotal');
    }),
    isTipsDisabled: (0, _object.computed)('tenderTypeGuid', function () {
      var _this$get$findBy;

      return !(this.tenderTypeGuid && (_this$get$findBy = this.get('tenders').findBy('id', this.tenderTypeGuid)) !== null && _this$get$findBy !== void 0 && _this$get$findBy.get('isTipAllowed'));
    }),
    tipAmounts: (0, _object.computed)('calculations.tips', function () {
      var _this = this;

      if (this.get('calculations.tips')) {
        return Object.entries(this.get('calculations.tips')).sort().map(function (entry) {
          return entry[1];
        });
      }

      return this.tipPercentages.map(function (percentage) {
        return Math.round(_this.total * percentage / 1000000);
      });
    }),
    tipAmount: (0, _object.computed)('selectedTip', 'selectedTipCustom', 'total', 'isTipsDisabled', 'giftCardAppliedAmount', 'tipAmounts', function () {
      if (this.isTipsDisabled || this.giftCardAppliedAmount === this.total) {
        return 0;
      }

      if (this.selectedTip === 'custom') {
        var custom = this.selectedTipCustom || 0;
        return Math.round(parseFloat(custom) * 100);
      }

      return this.tipAmounts[this.selectedTip] || 0;
    }),
    totalWithTip: (0, _object.computed)('total', 'tipAmount', function () {
      return this.total + this.tipAmount;
    }),
    dueAmount: (0, _object.computed)('totalWithTip', 'giftCardAppliedAmount', function () {
      if (this.giftCardAppliedAmount) {
        return this.totalWithTip - this.giftCardAppliedAmount;
      }

      return this.totalWithTip;
    }),
    amountRangeViolation: (0, _object.computed)('orderType', 'subtotal', 'calculations', function () {
      var minOrderAmount = this.get('orderType.minOrderAmount') || undefined;
      var maxOrderAmount = this.get('orderType.maxOrderAmount') || undefined;

      if (this.total < minOrderAmount) {
        return "We\u2019re sorry, but your order does not meet the minimum amount of" + " ".concat((0, _usd.usd)([minOrderAmount]), " for your selected order type.");
      } else if (this.total > maxOrderAmount) {
        return "We\u2019re sorry, but your order surpasses the maximum amount of" + " ".concat((0, _usd.usd)([maxOrderAmount]), " for your selected order type.");
      }
    }),
    discountCodeApplied: (0, _object.computed)('discountCodeValid', 'calculations.activeCouponCode', 'discountCodeLoading', function () {
      return this.discountCodeValid && this.get('calculations.activeCouponCode') && !this.discountCodeLoading;
    }),
    discountCodeNotApplicable: (0, _object.computed)('discountCodeValid', 'calculations.activeCouponCode', 'discountCodeLoading', function () {
      return this.discountCodeValid && !this.get('calculations.activeCouponCode') && !this.discountCodeLoading;
    }),
    getParamSchedules: (0, _object.computed)(function () {
      var queryParamsSchedules = this.get('router.currentRoute.queryParams.schedules') || [];
      return queryParamsSchedules.map(function (schedule) {
        return "schedules[]=".concat(schedule);
      }).join('&');
    }),
    isCustomCashPayment: (0, _object.computed)('dueAmount', 'giftCardAppliedAmount', function () {
      if (this.dueAmount === 0 && !this.giftCardAppliedAmount && !this.isServedByDDDOrDSP()) {
        return true;
      }

      return false;
    }),
    // if mode changes from dineIn or to dineIn, clear localStorage orderTypeGuid and items if orderType changed
    validateStorage: function validateStorage() {
      var _getLocalStorage = (0, _getLocalStorage2.default)(this.locationRef),
          orderTypeGuid = _getLocalStorage.orderSettings.orderTypeGuid;

      var dineIn = this.dineIn;

      if (dineIn && orderTypeGuid !== 'DINE_IN' || !dineIn && orderTypeGuid === 'DINE_IN') {
        (0, _clearLocalStorage.default)(this.locationRef);
      }
    },
    // for setting all order settings at once
    setOrderSettings: function setOrderSettings(_ref) {
      var orderTypeGuid = _ref.orderTypeGuid,
          isDeferred = _ref.isDeferred,
          expectedTimeUtc = _ref.expectedTimeUtc,
          customerSelectedDay = _ref.customerSelectedDay;

      if (orderTypeGuid && isDeferred && expectedTimeUtc && customerSelectedDay) {
        this.setProperties({
          orderTypeGuid: orderTypeGuid,
          isDeferred: isDeferred,
          expectedTimeUtc: expectedTimeUtc,
          customerSelectedDay: customerSelectedDay
        });
        (0, _setLocalStorage.default)(this.locationRef, {
          orderSettings: {
            orderTypeGuid: orderTypeGuid,
            isDeferred: isDeferred,
            expectedTimeUtc: expectedTimeUtc,
            customerSelectedDay: customerSelectedDay
          }
        });
      } else if (orderTypeGuid && isDeferred === false) {
        this.setProperties({
          orderTypeGuid: orderTypeGuid,
          isDeferred: isDeferred,
          expectedTimeUtc: undefined,
          customerSelectedDay: undefined
        });
        (0, _setLocalStorage.default)(this.locationRef, {
          orderSettings: {
            orderTypeGuid: orderTypeGuid,
            isDeferred: isDeferred,
            expectedTimeUtc: undefined,
            customerSelectedDay: undefined
          }
        });
      }
    },
    // for setting one singular order setting
    setOrderSetting: function setOrderSetting(key, value) {
      this.set(key, value);
      (0, _setLocalStorage.default)(this.locationRef, {
        orderSettings: _defineProperty({}, key, value)
      });
    },
    items: [],
    subtotal: (0, _object.computed)('items.@each.subtotal', function () {
      return this.items.reduce(function (prev, curr) {
        return prev + curr.get('subtotal');
      }, 0);
    }),
    clearCart: function clearCart() {
      this.items.forEach(function (item) {
        return item.destroyRecord();
      });
      this.setProperties({
        items: [],
        selectedTip: undefined,
        selectedTipCustom: undefined
      });
      return this.calculate();
    },
    clearOrderSettings: function clearOrderSettings() {
      this.setProperties({
        orderTypeGuid: undefined,
        isDeferred: undefined,
        expectedTimeUtc: undefined,
        selectedTip: this.get('preselectedTip'),
        customerSelectedDay: undefined
      });
    },
    clearLoyaltyInfo: function clearLoyaltyInfo() {
      this.set('loyaltyDiscount', undefined);
    },
    getDiscounts: function getDiscounts(includeCouponDiscounts) {
      var discounts = [];

      if (this.loyaltyDiscount) {
        discounts.push({
          discountRef: this.get('loyaltyDiscount.id'),
          source: this.get('loyaltyDiscount.source')
        });
      }

      if (includeCouponDiscounts) {
        var couponCode = this.getValidatedDiscountCode();

        if (couponCode) {
          discounts.push({
            discountRef: couponCode.discountRef,
            source: couponCode.source
          });
        }
      }

      return discounts;
    },
    getCart: function getCart() {
      var includeCouponDiscounts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      return {
        discounts: this.getDiscounts(includeCouponDiscounts),
        items: this.items.map(function (item) {
          return item.serialize({
            includeId: true,
            tree: true
          });
        }),
        orderTypeRef: this.orderTypeGuid,
        menuScheduleRef: this.menuScheduleRef
      };
    },
    calculate: function calculate() {
      var _this2 = this;

      var validateOutOfStockItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var revalidateDiscountCodes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (this.get('items.length') === 0 || !this.orderTypeGuid) {
        this.setProperties({
          calculations: undefined,
          calculateErrors: undefined
        });
        return (0, _rsvp.resolve)();
      }

      this.set('calculateLoading', true);
      var url = "".concat(_environment.default.host, "/api/v1/public/").concat(this.locationRef, "/cart/calculate");

      if (this.featureFlag.isEnabled('skytab-online-ooc-calculate-cart')) {
        url = "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/stores/").concat(this.locationRef, "/calculate-cart");
      }

      if (this.featureFlag.isEnabled('sto-nested-mods')) {
        url = "".concat(_environment.default.host, "/api/v2/public/").concat(this.locationRef, "/cart/calculate");
      }

      var data = this.getCart(false);
      data.tips = this.get('tipSettings') || undefined;
      data.giftAmount = this.get('giftCardAppliedAmount') || undefined;
      data.isAtCheckout = this.isQuantityOnHandFeatureEnabled && validateOutOfStockItems;
      data.couponCode = this.get('discountCode') || undefined; // If this is just a revalidation do not clear the previous discount erros. Those should be still visible to the user.

      if (!revalidateDiscountCodes) {
        this.setProperties({
          discountCodeValid: false,
          discountCodeError: undefined
        });
      } // get ticket calculations from backend


      return this.ajax.post(url, {
        data: data
      }).then(function (calculations) {
        _this2.setProperties({
          calculateErrors: undefined,
          calculations: calculations
        });

        return calculations;
      }).catch(function (e) {
        // special handling for items in cart that are no longer available
        var itemsNotFound = [];
        var itemsOutOfStock = [];
        var discountCodeError;
        e.payload.errors.forEach(function (error) {
          if (error.code === 'ITMNA') {
            itemsNotFound.push(error.metadata.InvalidItemRef);
          } else if (_this2.isQuantityOnHandFeatureEnabled && error.code === 'ITMOOSTK') {
            itemsOutOfStock.push(error.metadata.InvalidItemRef);
          } else if (error.code === 'ORDCOUPINV') {
            discountCodeError = "".concat(_this2.get('discountCode'), " is invalid");
          } else if (error.code === 'ORDCOUPINSTOT') {
            discountCodeError = 'Insufficient order total to apply the coupon';
          }
        });

        if (discountCodeError) {
          _this2.setOrderSetting('discountCode', null);

          _this2.set('discountCodeError', discountCodeError);

          if (!revalidateDiscountCodes) {
            // If we get a discount code error we will clear the current discountCode and do the calculations again. 
            return _this2.calculate(undefined, true);
          }
        }

        if (itemsNotFound.length > 0) {
          itemsNotFound.forEach(function (item) {
            var cartItems = _this2.items.filter(function (cartItem) {
              return cartItem.get('item.id') === item || !cartItem.get('item.id');
            });

            cartItems.forEach(function (cartItem) {
              cartItem.destroyRecord();

              _this2.items.removeObject(cartItem);
            });
          }); // after we remove the offending items, we should push a warning message
          // and recalculate to see if the errors are gone now

          _this2.set('itemsRemoved', itemsNotFound.length);

          (0, _runloop.later)(function () {
            return _this2.set('itemsRemoved', undefined);
          }, 10000);
          return _this2.calculate();
        }

        if (_this2.isQuantityOnHandFeatureEnabled && validateOutOfStockItems && itemsOutOfStock.length) {
          _this2.set('itemsOutOfStock', itemsOutOfStock.uniq());

          return (0, _rsvp.reject)('ITMOOSTK');
        }

        _this2.setProperties({
          calculateErrors: e.payload.errors,
          calculations: undefined
        });
      }).finally(function () {
        if (_this2.giftCardAppliedAmount) {
          _this2.redeemGiftCardAmount(_this2.giftCardAppliedAmount);
        }

        if (_this2.get('discountCode')) {
          if (!_this2.getValidatedDiscountCode()) {
            _this2.set('discountCodeError', 'Coupon code not found');

            return;
          }

          _this2.set('discountCodeValid', true);
        }

        if (_this2.get('isCustomCashPayment') && _this2.get('tenderTypeGuid') !== 'CASH') {
          _this2.set('tenderTypeGuid', 'CASH');

          _this2.customerService.set('selectedPaymentType', 'CASH');
        } else if (!_this2.get('isCustomCashPayment') && _this2.get('tenderTypeGuid') === 'CASH' && !_this2.get('tenders').findBy('id', 'CASH')) {
          _this2.set('tenderTypeGuid', 'NON_CASH');

          _this2.customerService.set('selectedPaymentType', 'NON_CASH');
        }

        _this2.set('calculateLoading', false);
      });
    },
    validateDistance: function validateDistance(address, orderType) {
      var _this3 = this;

      var url = this.featureFlag.isEnabled('skytab-online-ooc-validate-distance') ? "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/stores/").concat(this.locationRef, "/validate-distance") : "".concat(_environment.default.host, "/api/v1/public/").concat(this.locationRef, "/validate-distance");
      var data = {
        orderTypeRef: orderType.get('id'),
        addressLine1: address.get('addressLine1'),
        city: address.get('city'),
        state: address.get('state'),
        zip: address.get('zip')
      };

      if (this.get('preferredQuoteId') && this.featureFlag.isEnabled('sto-dsp-integration') && orderType.deliveryServedWithDSP) {
        data.preferredQuoteId = this.get('preferredQuoteId');
      }

      return this.ajax.post(url, {
        data: data
      }).then(function (response) {
        _this3.set('distanceLeadTimeInMinutes', response.eta ? (0, _timeUtil.differenceInMinutes)(new Date(), response.eta) : null);

        if (!response.isInRange && _this3.get('preferredQuoteId') && response.message == 'There are no eligible drivers for your quote request') {
          //If the previous delivery service is not available remove the previous preferredQuoteId and try again to get a new preferredQuoteId
          _this3.set('preferredQuoteId', undefined);

          return _this3.validateDistance(address, orderType);
        }

        if (_this3.featureFlag.isEnabled('sto-dsp-integration') && orderType.deliveryServedWithDSP) {
          _this3.set('preferredQuoteId', response.preferredQuoteId);
        }

        return !response.isInRange ? (0, _rsvp.reject)(response) : (0, _rsvp.resolve)(response);
      }).catch(function (payload) {
        if ((0, _errors.isAjaxError)(payload)) {
          var _error = (0, _generalErrorResponseParser.default)(payload.payload, {
            fallbackMessage: 'Request error(s)'
          });

          return (0, _rsvp.reject)({
            message: _error.friendlyError || _error.message
          });
        }

        var error = (0, _generalErrorResponseParser.default)(payload, {
          fallbackMessage: 'Request error(s)'
        });
        return (0, _rsvp.reject)({
          message: error.friendlyError || error.message,
          staticMapUrl: payload.staticMapUrl
        });
      });
    },
    validateDiscountCode: function validateDiscountCode() {
      var _this4 = this;

      var discountCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.discountCode;
      this.set('discountCodeLoading', true);

      if (discountCode) {
        this.setOrderSetting('discountCode', discountCode);
        return this.calculate().finally(function () {
          return _this4.set('discountCodeLoading', false);
        });
      }

      this.setOrderSetting('discountCode', null);
      return this.calculate().finally(function () {
        return _this4.set('discountCodeLoading', false);
      });
    },
    getValidatedDiscountCode: function getValidatedDiscountCode() {
      if (this.get('calculations.discounts')) {
        return this.get('calculations.discounts').findBy('source', 'coupon');
      }
    },
    getLocationAvailability: function getLocationAvailability(orderType) {
      var baseUrl = this.get('featureFlag').isEnabled('sto-nested-mods') ? "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v3/").concat(this.locationRef, "/availability") : "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/").concat(this.locationRef, "/availability");
      return this.get('ajax').request("".concat(baseUrl, "/time-slots?orderType=").concat(orderType.get('id')));
    },
    getAvailableTimeSlots: function getAvailableTimeSlots() {
      var _this5 = this;

      var orderType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.orderType;
      return this.getLocationAvailability(orderType).then(function (response) {
        // TODO : This code should be removed after LH-11662 (BE) is deployed
        if (response.asapTimeSlot) {
          response.asapTimeSlot.isAvailable = response.asapTimeSlot.isAvailable !== false;
        }

        if (response.futureTimeSlots) {
          var minTimeSlot = (0, _timeUtil.addMinutes)(new Date(), _this5.getLeadTimeInMinutes(orderType));
          response.futureTimeSlots.forEach(function (timeSlot) {
            timeSlot.isAvailable = timeSlot.isAvailable !== false && new Date(timeSlot.expectedTime) >= minTimeSlot;
          });
        }

        return response;
      }).catch(function (_ref2) {
        var payload = _ref2.payload,
            message = _ref2.message;

        /** This is here as a result of LH-4206 & LH-4207 and it should be temporary until
         * work is done on the backend to ensure proper error message spelling/casing
         * @param {string} errMessage the error message returned from the backend
         * @returns {string} transformedErrorMessage
        */
        var transformErrorMessage = function transformErrorMessage(errMessage) {
          return errMessage.replace(/TAKNTAVAIL/i, 'takeout orders are not available at this time.').replace(/This location is not available at this time/, 'location is not available at this time.');
        };

        if (payload && payload.errors) {
          var error = (0, _generalErrorResponseParser.default)(payload.errors, {
            fallbackMessage: "Available time slots error for ".concat(orderType.get('id'))
          });
          return (0, _rsvp.reject)(transformErrorMessage(error.friendlyError || error.message));
        }

        return (0, _rsvp.reject)(transformErrorMessage(message || "Available timeslots error for ".concat(orderType.get('id'))));
      });
    },
    getOrderTime: function getOrderTime() {
      var _this6 = this;

      var orderType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.orderType;
      var expectedTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.expectedTimeUtc;
      return this.getAvailableTimeSlots(orderType).then(function (timeSlots) {
        if (timeSlots.futureTimeSlots && timeSlots.futureTimeSlots.length > 0) {
          var selectedTime = timeSlots.futureTimeSlots.find(function (timeSlot) {
            return timeSlot.isAvailable && timeSlot.expectedTime === expectedTime;
          });

          if (selectedTime && _this6.isServedByDDDOrDSP(orderType)) {
            return (0, _rsvp.resolve)((0, _timeUtil.addMinutes)(new Date(selectedTime.expectedTime), -_this6.get('distanceLeadTimeInMinutes')).toISOString());
          } else if (selectedTime) {
            return (0, _rsvp.resolve)(selectedTime.orderTime);
          }
        }

        return (0, _rsvp.reject)('Your chosen order time is no longer available.');
      });
    },
    getGiftCardBalance: function getGiftCardBalance(giftCardNumber) {
      var baseUrl = "".concat(_environment.default.host, "/api/v1/public/").concat(this.locationRef, "/gift");
      return this.ajax.request("".concat(baseUrl, "/balance?code=").concat(giftCardNumber)).then(function (response) {
        return (0, _rsvp.resolve)(response.balance);
      }).catch(function (_ref3) {
        var payload = _ref3.payload;

        if (payload && payload.message) {
          return (0, _rsvp.reject)(payload.message);
        }

        return (0, _rsvp.reject)('Something went wrong, please try again');
      });
    },
    clearGiftCardDetails: function clearGiftCardDetails() {
      this.setProperties({
        giftCardAppliedAmount: undefined,
        giftCardNumber: undefined,
        giftCardBalance: undefined,
        giftCardErrors: undefined
      });
    },
    getGiftCardAmountToApply: function getGiftCardAmountToApply(amountToApply) {
      var balance = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.giftCardBalance;

      if (!amountToApply) {
        throw new Error('Enter a valid amount. Example: 2.00.');
      } else if (amountToApply <= 0) {
        throw new Error('Gift amount must be more than $0.00.');
      } else if (amountToApply <= balance) {
        if (amountToApply < this.total) {
          return amountToApply;
        }

        return this.total;
      } else {
        throw new Error("You only have ".concat((0, _usd.usd)([balance]), " remaining. Enter amount less than ").concat((0, _usd.usd)([balance]), "."));
      }
    },
    redeemGiftCardAmount: function redeemGiftCardAmount(amountToApply) {
      try {
        this.set('giftCardAppliedAmount', this.getGiftCardAmountToApply(amountToApply));
        this.set('giftCardErrors', undefined);
      } catch (error) {
        this.set('giftCardErrors', error.message);
        this.set('giftCardAppliedAmount', undefined);
      }
    },
    reApplyGiftCardAmount: function reApplyGiftCardAmount() {
      var _this7 = this;

      if (this.get('giftCardAppliedAmount') && this.giftCardNumber) {
        return this.getGiftCardBalance(this.giftCardNumber).then(function (balance) {
          _this7.setProperties({
            giftCardErrors: undefined,
            giftCardBalance: balance
          });
        }).then(function () {
          return _this7.redeemGiftCardAmount(_this7.giftCardAppliedAmount);
        }).catch(function (message) {
          _this7.setProperties({
            giftCardErrors: message,
            giftCardBalance: undefined
          });
        }).finally(function () {
          return (0, _rsvp.resolve)();
        });
      }

      return (0, _rsvp.resolve)();
    },
    validateAppliedGiftCardAmount: function validateAppliedGiftCardAmount() {
      var _this8 = this;

      if (!this.giftCardBalance) {
        return (0, _rsvp.resolve)();
      }

      return this.getGiftCardBalance(this.giftCardNumber).then(function (balance) {
        try {
          if (_this8.getGiftCardAmountToApply(_this8.giftCardAppliedAmount) !== _this8.giftCardAppliedAmount) {
            _this8.set('giftCardErrors', 'Error in applying gift card balance. Please retry again.');
          }
        } catch (error) {
          _this8.setProperties({
            giftCardErrors: error.message,
            giftCardBalance: balance,
            giftCardAppliedAmount: undefined
          });
        }
      }).catch(function (message) {
        _this8.setProperties({
          giftCardErrors: message,
          giftCardBalance: undefined,
          giftCardAppliedAmount: undefined
        });
      }).finally(function () {
        if (_this8.giftCardErrors) {
          return (0, _rsvp.reject)();
        }
      });
    }
  });

  _exports.default = _default;
});