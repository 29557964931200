define("lh-public-olo/utils/set-local-storage", ["exports", "@ember/utils", "lh-public-olo/utils/get-local-storage", "lh-public-olo/utils/has-local-storage"], function (_exports, _utils, _getLocalStorage, _hasLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setLocalStorage;

  /**
   * Sets data in localStorage for locations
   *
   * @method setLocalStorage
   *
   * @param {string} locationRef - id for location related to data
   * @param {Object} data - data to store in localStorage
   * @param {Object[]} data.items - array of cart-items
   * @param {Object} data.orderSettings - array of cart-items
   * @param {string} data.orderSettings.orderTypeGuid - id for order type
   * @param {boolean} data.orderSettings.isDeferred - whether or not the order is deferred
   * @param {string} data.orderSettings.discountCode - user entered discount code
   * @param {string} data.orderSettings.menuScheduleRef - ref for menu schedule selected by user
   * @param {string} data.orderSettings.tableNotes - table notes for dineIn order type
   * @param {string} data.orderSettings.expectedTimeUtc - Expected UTC date of the deferred order
   */
  function setLocalStorage(locationRef, _ref) {
    var items = _ref.items,
        _ref$orderSettings = _ref.orderSettings,
        orderTypeGuid = _ref$orderSettings.orderTypeGuid,
        isDeferred = _ref$orderSettings.isDeferred,
        discountCode = _ref$orderSettings.discountCode,
        menuScheduleRef = _ref$orderSettings.menuScheduleRef,
        tableNotes = _ref$orderSettings.tableNotes,
        expectedTimeUtc = _ref$orderSettings.expectedTimeUtc,
        customerSelectedDay = _ref$orderSettings.customerSelectedDay,
        referralSource = _ref$orderSettings.referralSource,
        referralGuid = _ref$orderSettings.referralGuid;

    if (!(0, _hasLocalStorage.default)()) {
      return;
    } // if something wasn't passed in, grab the old info out of localStorage
    // useful for things like setting orderTypeGuid where you're not pulling localStorage originally


    if (items === undefined || orderTypeGuid === undefined || isDeferred === undefined || discountCode === undefined || menuScheduleRef === undefined || tableNotes === undefined || expectedTimeUtc === undefined || customerSelectedDay === undefined || referralSource === undefined || referralGuid === undefined) {
      var data = (0, _getLocalStorage.default)(locationRef);
      items = items || data.items;
      orderTypeGuid = orderTypeGuid || data.orderSettings.orderTypeGuid;
      isDeferred = isDeferred !== undefined ? isDeferred : data.orderSettings.isDeferred;
      discountCode = discountCode !== undefined ? discountCode : data.orderSettings.discountCode;
      menuScheduleRef = menuScheduleRef !== undefined ? menuScheduleRef : data.orderSettings.menuScheduleRef;
      tableNotes = tableNotes !== undefined ? tableNotes : data.orderSettings.tableNotes;
      expectedTimeUtc = isDeferred ? expectedTimeUtc || data.orderSettings.expectedTimeUtc : undefined;
      customerSelectedDay = customerSelectedDay !== undefined ? customerSelectedDay : data.orderSettings.customerSelectedDay;
      referralSource = referralSource !== undefined ? referralSource : data.orderSettings.referralSource;
      referralGuid = referralGuid !== undefined ? referralGuid : data.orderSettings.referralGuid;
    }

    if (!(0, _utils.isEmpty)(items) || orderTypeGuid !== undefined || isDeferred !== undefined || discountCode !== undefined || menuScheduleRef !== undefined || tableNotes !== undefined || expectedTimeUtc !== undefined || referralSource !== undefined || referralGuid !== undefined) {
      localStorage.setItem(locationRef, JSON.stringify({
        items: items,
        orderSettings: {
          orderTypeGuid: orderTypeGuid,
          isDeferred: isDeferred,
          discountCode: discountCode,
          menuScheduleRef: menuScheduleRef,
          tableNotes: tableNotes,
          expectedTimeUtc: expectedTimeUtc,
          customerSelectedDay: customerSelectedDay,
          referralSource: referralSource,
          referralGuid: referralGuid
        },
        expiresAt: Date.now() + 7.2e6
      }));
    } else {
      localStorage.removeItem(locationRef);
    }
  }
});